import React, { Component } from 'react';


import Layout from 'src/components/layout';
import Disclaimer from '../../components/disclaimer';
import ConsumerPanel from '../../components/consumer-panel';

import { AppStateContext } from 'src/contexts/app-state-provider.context';

import PersonalResourcesImg from '../../images/personal_resources.jpg';
import ReactGA from "react-ga4";
import { BannerComponent } from '../../components/banner-component';
import  ThreeCardStructure  from '../../components/three-column-cards';



import ConsultDoctor from '../../components/consult-doctor';

import './index.scss';

class PatientPage extends Component {
  static contextType = AppStateContext;
  state = {
    isAudioVisible: false
  };
  componentDidMount() {
    this.context.setSection('patient');
    this.context.setPage('patient-resources');
  }

  trackDownloads(category,action){
    ReactGA.event({
      category,
      action
    });
  }

  render() {
    const title = {
      english: 'Patient Resources'
    };
    return (

     
      <Layout title={title} showNavbar={true} className="patient">
         <BannerComponent backgroundPhoto={PersonalResourcesImg} titleText="Patient Resources"/>
         <div className="container">
            <h3 className='py-4'>Take advantage of these downloadable resources to support you on your treatment journey.</h3>
          </div>
        <ThreeCardStructure />
        <ConsultDoctor bgColor="yellow"/>
        <Disclaimer />
        <ConsumerPanel />
      </Layout>
    );
  }
}

export default PatientPage;
